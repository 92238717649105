.sar-table {
  width: 100%;

  .od {
    display: flex;
    position: relative;
    
    align-items: center;
    gap: 20px;

    svg {
      color: #CB8300;
    }

    &:hover {
      .tooltip {
        display: flex;
        opacity: 1;
        transition: all 0.15s ease;
      }
    }
  }

  table {
    width: 100%;
    thead {
      width: 100%;
    }

    tr {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(20, 1fr);
      gap: 10px;
      padding: 25px 10px;

      th {
        display: flex;
        align-items: center;
        text-align: left;
        grid-column: span 1;
        padding-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #B3B3B3;
      }

      td {
        display: flex;
        align-items: center;
        grid-column: span 1;
        padding-left: 30px;
        font-size: 14px;
        font-weight: 300;
        color: #212731;

        .status {
          display: flex;
          height: 22px;
          width: 22px;
          border-radius: 50%;
        }
      }

      td:nth-child(1) {
        padding-left: 10px;
      }
      th:nth-child(1) {
        padding-left: 10px;
      }
    }

    tbody {
      width: 100%;
    }
  }

  .circulation {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}