@import "assets/css/constants.scss";

.flux {
  width: 100%;

  .accordion {
    h3 {
      padding: 50px 20px;
      background-color: #F6F6F6;
      font-size: 16px;
      font-weight: 400;

      svg {
        color: $color-main;
      }
    }
  }

  .flux-item {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    gap: 10px;
    padding: 30px 10px;

    > th {
      display: flex;
      align-items: center;
      text-align: left;
      grid-column: span 1;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 400;

      &:nth-child(7) {
        padding-left: 30px;
      }
      &:nth-child(8) {
        padding-left: 30px;
      }

      svg {
        color: $color-main;
      }

      .status {
        display: flex;
        height: 22px;
        width: 22px;
        border-radius: 50%;
      }
    }
  }
}