@import 'assets/css/constants.scss';

.tooltip {
  position: absolute;
  display: none;
  opacity: 0;
  left: 19px;
  top: -50px;
  width: max-content;

  .message {
    position: relative;
    gap: 12px;
    padding: 8px 16px;
    max-width: 700px;
    color: #CB8300;
    font-size: 12px;
    font-weight: 400;
    border-radius: 12px;
    background: $color-white;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    white-space: pre-line;
    z-index: 2;

    &::before {
      content: '';
      z-index: 1;
      position: absolute;
      bottom: -7px;
      height: 15px;
      width: 15px;
      rotate: 45deg;
      left: 30px;
      border: none;
      background: $color-white;
      box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1), 0 0 0px rgba(0, 0, 0, 0.05);
    }
  }

  &.active {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    align-items: end;
    pointer-events: none;
    transition: all 0.15s ease;
    opacity: 1;
  }
}