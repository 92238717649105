@import 'assets/css/constants.scss';

@mixin button-modifier($color, $fontColor, $borderColor, $class) {
  &.button--#{$class} {
    background: $color;
    color: $fontColor;
    border: 1px solid $borderColor;

    &:hover {
      background: darken($color, 5%);
    }
  }
};

@mixin button-size($pading, $fontSize, $class) {
  &.button--#{$class} {
    padding: $pading;
    font-size: $fontSize;
  }
};

.MuiButtonBase-root.simple-button {
  padding: 3px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: unset;
  text-transform: unset;
  border-radius: 10px;

  svg {
    margin-right: 8px;
  }

  &:hover {
    outline: 0;
    text-decoration: none;
    box-shadow: none;
  }

  @include button-size(3px 16px, 14px, small);
  @include button-size(6px 16px, 16px, medium);
  @include button-size(8px 16px, 18px, large);
  @include button-size(18px 30px, 18px, xlarge);

  @include button-modifier($color-main, $color-white, transparent, primary);
  @include button-modifier($color-secondary, $color-white, $color-secondary, light);
  @include button-modifier(transparent, $color-black, transparent, borderLess);
  @include button-modifier(transparent, $color-white, transparent, borderLessWhite);
  @include button-modifier(transparent, $color-main, transparent, borderLessPrimary);
  @include button-modifier(#DA4238, $color-white, #DA4238, delete);

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}