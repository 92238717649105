.filter-fluxs {
  width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 60px;

  h2 {
    text-align: center;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}