@import "assets/css/constants.scss";

.input-wrapper.select {

  &.error {
    select {
      border-color: $color-error;
      color: $color-error;
    }
  }
  select {
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    .optionEmpty {
      display: none;
    }
  }
}