@import 'assets/css/constants.scss';

.info-banner {
  position: absolute;
  display: none;
  right: 20px;
  top: 200px;

  .message {
    position: relative;
    gap: 12px;
    padding: 8px 16px;
    max-width: 700px;
    color: $color-main;
    font-size: 16px;
    font-weight: 400;
    border-radius: 12px;
    background: $color-white;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    white-space: pre-line;

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      height: 15px;
      width: 15px;
      rotate: 45deg;
      right: 30px;
      background-color: $color-white;
      border-radius: 3px;
      box-shadow: -2px -2px 4px 1px rgba(0, 0, 0, 0.05), 0 0 0px 0px rgba(0, 0, 0, 0.05);
    }
  }

  &.active {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    align-items: end;
  }
}