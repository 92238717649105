@import 'assets/css/constants.scss';

.mission-home {
  padding: 24px;

  h3 {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 18px;
    color: #000000;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 430px;
    background-color: #F6F6F6;
    padding: 24px;
    border-radius: 24px;

    label {
      font-size: 12px;
      font-weight: 400;
      color: #525967;
    }
  }
}