@import 'assets/css/constants.scss';

.autocomplete-input {
  position: relative;

  .text.input-wrapper input {
    padding-right: 50px;
  }

  .loader {
    display: none;
    position: absolute;
    right: 5px;
    bottom: 5px;
    transform: scale(0.7);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.loading {
    .loader {
      display: block;
    }
  }

  .input-chip-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      font-size: 12px;
      color: #161616;
      font-weight: bold;
    }

    .input-with-chips {
      border: solid 1px #D9DADC;
      padding: 12px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 400;
      color: #212731;
      flex: 1;
  
      .chips {
        display: flex;
        gap: 10px;
        align-items: center;
  
        .chip {
          background-color: #3A4457;
          color: white;
          display: flex;
          align-items: center;
          padding: 4px 8px;
          border-radius: 5px;
          gap: 4px;
          font-size: 12px;
          margin-bottom: 10px;
  
          button {
            border: none;
            cursor: pointer;
            background: transparent;
            color: white;
            display: flex;
            align-items: center;
  
            svg {
              font-size: 16px;
            }
          }
        }
      }

      input {
        width: 100%;
        border: none;
        background: transparent;
        font-size: 16px;
        font-weight: 400;
        color: #212731;
        flex: 1;
        outline: none;
        padding: 0;
        border-radius: 0;


        &:focus {
          border: none;
          padding: 0;
        } 
      }
    }
  }
  

  .results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 999;
    max-height: 160px;
    background-color: #FFF;
    border: 0px solid transparent;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0);
    overflow-y: auto;
    padding: 0;

    .result {
      padding: 8px 16px;
      cursor: pointer;
      transition: all 0.15s ease-in;

      &.add {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      &:hover {
        background-color: #F2F2F2;
      }
    }

    &.has-results {
      padding: 8px 0;
      border: 1px solid #D9DADC;
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }
  }
}