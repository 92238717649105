.flux-home {
  width: 80%;
  padding: 24px;

  .banner {
    display: none;
    width: 100%;
    gap: 10px;
    margin-bottom: 15px;
    color: #CB8300;
    border: 1px solid #CB8300;
    padding: 18px;
    border-radius: 4px;
    align-items: center;
    white-space: pre-line;

    &.active {
      display: flex;
      
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 18px;
    color: #000000;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 680px;
    background-color: #F6F6F6;
    padding: 24px;
    border-radius: 24px;

    .client-input {
      display: flex;
      align-items: center;
      gap: 12px;

      .autocomplete-input {
        width: 100%;
      }

      .add-client {
        border: none;
        background: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-top: 18px;

        svg {
          height: 30px;
          width: 30px;
        }
      }
    }

    label {
      font-size: 12px;
      font-weight: 400;
      color: #525967;
    }

    .row {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      > div {
        width: 48%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        span {
          font-size: 12px;
          font-weight: 400;
          color: #525967;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;

    .button--primary {
      padding: 10px 20px;
    }
  }
}