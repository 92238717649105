@import "assets/css/constants.scss";

.trames {
  margin-top: 30px;

  h3 {
    font-size: 24px;
    font-weight: 800;
    color: #000000;
  }

  .alerte-info {
    display: flex;
    gap: 10px;
    align-items: center;
    visibility: hidden;
    font-size: 16px;
    font-weight: 400;
    color: $color-error;

    .icon {
      color: $color-error;
    }

    &.active {
      visibility: visible;
    }
  }

  .trames-wrapper {
    display: flex;
    gap: 10px;

    > div {
      display: flex;
      gap: 10px;

      .time-interval {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 60px;

        div {
          width: 80px;
          height: 100px;
          background-color: #F5F9FF;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 12px;

          .icon {
            color: $color-main;
          }
        }
      }
    }

    .trame-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 10px 10px 50px 10px; 
      width: 280px;
      border-radius: 12px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin-top: 30px;
      position: relative;
      height: max-content;

      .actions {
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        top: -15px;
        right: 10px;
        
        > div {
          display: flex;
          align-items: center;
          gap: 10px;

          button {
            background: transparent;
            border: none;
            cursor: pointer;

            &:disabled {
              cursor: not-allowed;
            }
          }
        }
      }

      .detail {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .trame {
          display: flex;
          width: 100%;
          padding: 0px 20px;
          gap: 2px;
          align-items: center;
          justify-content: space-between;
  
          .stage {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-size: 14px;
            position: relative;
            padding: 10px 0px 10px 0px;
  
            .place {
              width: max-content;
              position: absolute;
              top: 35px;
              font-weight: bold;
              &.error {
                color: $color-error;
              }
            }
  
            .hour {
              width: max-content;
              position: absolute;
              top: 55px;
              display: flex;
              align-items: center;
  
              svg {
                height: 20px;
              }
  
              > div {
                display: flex;
                align-items: center;
              }
            }
  
            .day {
              width: max-content;
              position: absolute;
              top: 75px;
              color: #A3ADC2;
            }
  
            .chip {
              width: 15px;
              height: 15px;
              background-color: #595E63;
              border-radius: 50%;
              color: #595E63;

              &.error {
                background-color: $color-error;
              }
            }
          }
  
          .section-name {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            margin-top: 25px;
            background: transparent;
            position: relative;
  
            div {
              padding: 0px 0px 20px 5px;
            }
  
            span {
              font-size: 16px;
              width: max-content;
              position: absolute;
              top: 35px;
            }
          }
  
          .line {
            width: 100%;
            background-color: #595E63;
            height: 2px;
          }
        }
      }

      .edit {
        padding: 30px 0px 0px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    .add {
      margin-top: 25px;
      margin-left: 20px;
      border: none;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
      height: 250px;
      min-height: 250px;
      min-width: 280px;
      width: 280px;
      background-color: #EAF1FF;
      color: #256EFF;
      cursor: pointer;
      font-size: 16px;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}