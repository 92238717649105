#fluxsPage {
  width: 100%;
  .table-header {
    table {
      width: 100%;
      thead {
        width: 100%;
      }
    }
    padding: 20px 20px 0 20px; 
    tr {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(20, 1fr);
      gap: 10px;
      padding: 40px 20px;
      background-color: #212731;
      color: white;
      border-radius: 5px;
  
      th {
        text-align: left;
        grid-column: span 1;
      }
    }
  }

  .fluxs {
    padding: 20px;
    gap: 18px;
    
  }

  @media screen and (max-width: 1000px) {
    width: 1000px;
  }
}