.timeline {
  display: flex;
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 10px;
  gap: 2px;
  align-items: center;
  justify-content: space-between;

  .stage.previous-highlighted {
    .chip {
      background-color: #256EFF;
    }

    .hour {
      color: #256EFF;
    }
    span {
      color: #256EFF;
    }
  }

  .highlighted {
    color: #256EFF;
    .line {
      background-color: #256EFF;
    }
    .stage .chip {
      background-color: #256EFF;
    }
  }

  .stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 16px;
    position: relative;
    padding: 10px;

    .place {
      width: max-content;
      position: absolute;
      top: 40px;
    }

    .hour {
      width: max-content;
      position: absolute;
      top: 60px;
      display: flex;
      align-items: center;

      svg {
        height: 20px;
      }

      > div {
        display: flex;
        align-items: center;
      }
    }

    .chip {
      padding: 0px 10px 20px 10px;
      width: 20px;
      height: 20px;
      background-color: #595E63;
      border-radius: 50%;
      color: #595E63;
    }
  }

  .section-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 25px;
    background: transparent;
    position: relative;

    div {
      padding: 0px 8px 20px 10px;
    }

    span {
      font-size: 16px;
      width: max-content;
      position: absolute;
      top: 40px;
    }
  }

  .line {
    width: 100%;
    background-color: #595E63;
    height: 2px;
  }
}