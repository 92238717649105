.mission-side-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 20%;
  border-right: 1px solid #D7E1F3;
  padding: 24px;
  height: 100%;
  overflow-y: auto;

  > div {
    width: 100%;
  }

  .selected button {
    background-color: #D7E1F3;
  }

  .button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F9FF;
    height: 40px;
    border-radius: 8px;
    padding: 5px 10px;
    border: none;
    font-size: 16px;
    cursor: pointer;

    .icon {
      font-size: 20px;
      color: #3A4457;
    }

    &:hover {
      background-color: #D7E1F3;
    }
  }

  .accordions {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .accodion-content {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      gap: 10px;
      width: 90%;

      .section-button {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        gap: 5px;
        width: 90%;

        .engine {
          width: 90%;
          margin-left: auto;
        }

        .add {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          background-color: #F5F9FF;
          height: 40px;
          border-radius: 8px;
          padding: 5px 10px;
          border: none;
          font-size: 16px;
          cursor: pointer;

          &:hover {
            background-color: #D7E1F3;
          }
        }
      }
    }
  }
}