@import 'assets/css/constants.scss';

.flux-header {
  height: 104px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  background-color: $color-header-bar;
  align-items: center;

  .title {
    display: flex;
    align-items: center;
    gap: 20px;
    color: $color-white;

    .button--borderLess {
      font-size: 16px;
      &:hover {
        text-decoration: underline;
      }
    }

    > div {
      font-size: 24px;
      font-weight: 800;
    }

    > span {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #256EFF;
    }
  }

  .import-button {
    display: flex;
    justify-content: center;
    align-items: center;

    .button--light {
      height: 56px;
    }
  }
}

.import-sar-modal-children {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;

  .check-input-wrapper {
    display: flex;
    gap: 8px;

    span.success-message {
      gap: 8px;
    }
  }
}
