@import 'assets/css/constants.scss';

@mixin header-modifier($color, $background, $class) {
  .header--#{$class} {
    padding: 20px;
    color: $color;
    background-color: $background;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    
    svg {
      width: 70px;
      height: 60px;
    }
  }
}

@mixin info-modifier($color, $background, $border, $class) {
  .info--#{$class} {
    padding: 20px;
    color: $color;
    background-color: $background;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border;
    gap: 10px;
  }
}



.modal-import {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include header-modifier($color-success, $background-success, success);
  @include header-modifier($color-info, $background-info, info);
  @include header-modifier($color-warning, $background-warning, warning);
  @include header-modifier($color-error, $background-error, error);
  @include header-modifier($color-main-black, $color-white, default);

  @include info-modifier($color-main-black, $color-white, $color-white, success);
  @include info-modifier($color-main-black, $color-white, $color-white, info);
  @include info-modifier($color-main-black, $color-white, $color-white, warning);
  @include info-modifier($color-error, $color-white, $color-error, error);


  .error-icon {
    display: flex;
    align-items: center;
  }

  .messages-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .title {
    font-size: 18px;
    font-weight: 800;
    color: #161616;
    text-align: center;
  }

  .buttons {
    display: flex;
    justify-content: center;
    height: 40px;
    gap: 30px;

    button {
      font-size: 16px;
    }

    .cancel {
      border: none;
      background-color: transparent;
      color: $color-main;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
