.flux-sar {
  width: 80%;
  padding: 0;

  .info-banner .message {
    max-width: 650px;
  }

  .forms {
    display: flex;
    padding: 24px;
    gap: 12px;

    h3 {
      font-size: 18px;
      font-weight: 800;
      color: #000000;
    }  

    .info, .circulation {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .info {
      max-width: 480px;

      .form {
        gap: 18px;
      }
    }

    .circulation .form {
      gap: 16px;
    }

    .form {
      display: flex;
      flex-direction: column;
      background-color: #F6F6F6;
      padding: 24px;
      border-radius: 12px;
  
      .input-wrapper label, p > span:first-of-type {
        font-size: 12px;
        font-weight: 400;
        color: #525967;
      }
  
      &.static {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
  
        p {
          width: calc(50% - 12px);
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}