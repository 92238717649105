@import 'assets/css/constants.scss';

.create-mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  gap: 40px;

  h2 {
    font-size: 18px;
    font-weight: 800;
    color: $color-black;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    color: $color-main-black;
  }

  .buttons {
    display: flex;
    gap: 40px;
    height: 35px;
  }

  .mission-id {
    width: 100%;
  }
}