#fluxPage {
  .content {
    display: flex;
    width: 100%;
    height: calc(100% - 124px);
  }

  @media screen and (max-width: 1000px) {
    width: 1000px;
  }

  .buttons {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    .button--delete {
      padding: 10px 20px;
    }
  }
}