@mixin colorVariant($color, $background, $border, $fill) {
  background-color: $background;
  color: $color;
  border: 1px solid $border;

  svg {
    fill: $fill;
  }
}

.toast {
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  min-width: 250px;
  max-width: calc(100% - 40px);
  display: flex;
  padding: 8px 12px;
  border-radius: 6px;
  gap: 10px;
  font-size: 14px;
  opacity: 0;
  transform: translateX(calc(100% + 20px));
  transition: all 0.3s ease-in-out;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);

  svg {
    flex-shrink: 0;

    &:last-of-type {
      cursor: pointer;
      margin-left: auto;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  p {
    align-self: center;
  }

  &.info {
    @include colorVariant(#016093, #cce8f4, #6da2b6, #0487ce);
  }

  &.success {
    @include colorVariant(#135e01, #def2d6, #7db27e, #1d9000);
  }

  &.error {
    @include colorVariant(#88021d, #ebc8c4, #df9393, #a90526);
  }

  &.warning {
    @include colorVariant(#8a6a01, #f8f3d6, #bcb990, #b48b02);
  }

  &.displayed {
    opacity: 1;
    transform: translateX(0);
  }
}