@import 'assets/css/constants.scss';

.section-card {
  flex: 1 1;

  .alert-capacity {
    border: 2px solid $color-alert;

    .alert {
      color: $color-alert;
    }

    .alert-info {
      position: absolute;
      width: 80%;
      top: -45px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px 1px 7.7px -2px rgba(0, 0, 0, 0.15);
      background-color: #F6F6F6;
      padding: 7px;
      border-radius: 7px;
      color: $color-alert;
      transition: all 0.3s ease-in-out;
    
      span {
        display: block;
        font-size: 16px;
      }
    
      &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 80%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #F6F6F6;
      }
    }
    &:hover {
      .alert-info {
        display: flex;
        justify-content: center;
      }
    }
  }

  .wrapper {
    position: relative;
    min-height: 40px;
    padding: 11px;
    background-color: #F6F6F6;
    border-radius: 6px;
    min-width: 180px;

    .alert-info {
      display: none;
    }

    .info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 6px;
    background-color: white;
    border-radius: 3px;
    margin-bottom: 12px;

      > div {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: space-between;

        > div {
          font-size: 11px;
          line-height: 15px;

          &:first-of-type {
            font-weight: 400;
          }

          &:last-of-type {
            font-weight: 800;
            text-align: right;
            flex-shrink: 0; 
          }
        }
      }

      .hours {
        display: flex;
        gap: 3px;
        align-items: center;

        svg {
          height: 15px;
        }
      }

      .train-wrapper {
        display: flex;
        gap: 5px;
        font-size: 11px;
        align-items: start;

        .trains {
          display: flex;
          flex-direction: column;
          gap: 3px;
          font-weight: 800;
        }
      }
    }

    .lots {
      align-items: flex-start;
      width: 100%;
      gap: 12px;

      .sortable-lot {
        width: 100%;
        padding: 6px;
        border-radius: 3px;
        background-color: white;
        cursor: pointer;
      }

      .lot {
        width: 100%;
        padding: 6px;
        border-radius: 3px;
        background-color: white;

        &.study {
          border-style: dashed;
          border-color: $color-main;
          border-width: 2px;
          padding: 4px;
        }

        .title {
          justify-content: space-between;
          margin-bottom: 6px;

          .libelle {
            display: flex;
            align-items: center;
            gap: 3px;

            button {
              background: transparent;
              border: none;
              cursor: pointer;
              
              svg {
                height: 10px;
                
              }
            }

            h4 {
              font-size: 11px;
            }
          }

          svg {
            flex-shrink: 0;
          }
        }
  
        .details {
          justify-content: flex-start;
          gap: 6px;

          > div {
            display: flex;
            font-size: 11px;
            align-items: center;
            gap: 3px;
            padding: 3px;
            border-radius: 3px;
            background-color: #F6F6F6;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1350px) {
    .wrapper {
      padding: 6px;

      .info {
        margin-bottom: 6px;
      }

      .lots {
        gap: 6px;
      }
    }
  }
}