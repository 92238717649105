@import 'assets/css/constants.scss';

.fluxs-header {
  height: 100px;
  background-color: $color-header-bar;
  padding: 18px 24px;
  border-top: 0.5px solid $color-white;
  display: flex;
  align-items: center;

  .actions {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    height: 70px;
    background-color: $color-white;
    padding: 0px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    transition: all 0.3s ease;

    &:active {
      background-color: #fffffff1;
    }

    .filter {
      display: flex;
      width: 110px;
      height: 40px;
      align-items: center;
      background-color: $background-purple-light;
      font-size: 16px;
      font-weight: 400;
      justify-content: center;
      border-radius: 8px;
    }

    .filters-info {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 5px;
      justify-items: flex-start;

      .info {
        display: flex;
        align-items: center;
        gap: 5px;
  
        > div {
          font-weight: 800;
          font-size: 14px;
          width: 70px;
          text-align: right;
        }
  
        .chip {
          font-size: 12px;
          font-weight: 400;
          text-transform: capitalize;
          color: $color-white;
          background-color: $color-header-bar;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
        }

        span {
          font-size: 12px;
        }
  
      }
    }
  }
}