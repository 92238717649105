.mission-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  background-color: rgba(37, 110, 255, 0.15);

  .header {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 800;

    > span {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #256EFF;
    }

    .title {
      display: flex;
      gap: 6px;
      align-items: center;
      min-height: 32px;
      p {
        display: flex;
        gap: 5px;
      }
    }

    button {
      margin-left: auto;
    }
  }

  .sections {
    display: flex;
    gap: 16px;

    .section {
      flex: 1 1;

      .wrapper {
        min-height: 40px;
        padding: 11px;
        background-color: #F6F6F6;
        border-radius: 6px;

        .info {
          display: flex;
          flex-direction: column;
          gap: 6px;
          padding: 6px;
          background-color: white;
          border-radius: 3px;
  
          > div {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: space-between;
  
            > p {
              font-size: 11px;
              line-height: 15px;
  
              &:first-of-type {
                font-weight: 400;
              }
  
              &:last-of-type {
                font-weight: 800;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1350px) {
    padding: 8px;

    .sections {
      gap: 8px;
    }
  }
}