@import 'assets/css/constants.scss';

.text.input-wrapper {
  &.align-left {
    input {
      text-align: left;
    }
  }

  &.align-center {
    input {
      text-align: center;
    }
  }

  &.align-right {
    input {
      text-align: right;
    }
  }

  &.disabled {
    input {
      background-color: #D7E1F3;
      color: #A3ADC2;
    }
  }
}