#missionsPage {
  .missions {
    padding: 20px;
    gap: 18px;
  }

  @media screen and (max-width: 1000px) {
    width: 1000px;
  } 

  .infinite-scroll-component::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .infinite-scroll-component::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .infinite-scroll-component::-webkit-scrollbar-thumb {
    background: #c0bebe;
    border-radius: 5px;
  }
}