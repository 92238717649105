.accordion {
  width: 100%;

  h3 {
    background-color: #F5F9FF;
    font-weight: 400;
    height: 40px;
    display: flex;
    border-radius: 8px;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    transition: all 0.15s ease-in-out;
    font-size: 16px;

    .icon {
      font-size: 15px;
      transition: all 0.15s ease-out;
    }

    &:hover {
      background-color: #D7E1F3;
    }

    &.active {
      background-color: #D7E1F3;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: none;
    overflow: hidden;

    > * {
      flex-shrink: 0;
    }
  }

  &.expanded {
    > h3 {
      margin-bottom: 15px;
      background-color: #D7E1F3;

      .icon {
        transform: rotate(90deg);
      }  
    }
  }

  &.enable-transition .wrapper {
    transition: all 0.2s ease-in-out;
  }
}