.mission-variant {
  padding: 24px;
  width: 80%;
  height: calc(100vh - 180px);

  h3 {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 18px;
    color: #000000;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 270px;
    background-color: #F6F6F6;
    padding: 24px;
    border-radius: 24px;

    span {
      font-size: 12px;
      font-weight: 800;
      color: #3C3C3B;
    }
  }
}