
.input-wrapper.time {

  &.error {
    fieldset {
      border-color: #f44336;
    }
    .MuiFormControl-root {
      border-color: #f44336;
      .MuiOutlinedInput-root {
        border-color: #f44336;
        .MuiOutlinedInput-input {
          border-color: #f44336;
        }
      }
    }
  }
  .MuiFormControl-root {
    border-radius: 6px;
    border-color: #d5d5d5;
    .MuiOutlinedInput-root {
      border-radius: 6px;
      background-color: #FFF;
      border-color: #d5d5d5;
      &:focus {
        border: none;
        outline: none;
        border-color: #d5d5d5;
      }
      .MuiOutlinedInput-input {
        border: none;
        padding: 8px;
        background-color: #FFF;
        border-radius: 6px;
        border-color: #d5d5d5;

        &:focus {
          border: none;
          outline: none;
          border-color: #d5d5d5;
        }
      }
    }
  }
}
