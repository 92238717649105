@import '../../../assets/css/constants.scss';

.input-wrapper.date {
  .react-datepicker__input-container input {
    width: 100%;
  }

  .react-datepicker-popper .react-datepicker {
    border: none;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
    
    .react-datepicker__navigation-icon::before {
      border-color: $color-main;
    }

    .react-datepicker__navigation-icon:hover {
      transition: 0.15s ease-in-out;
      transform: scale(1.1);
    }

    .react-datepicker__header {
      background-color: $color-secondary;
      border-bottom: none;
    
    }

    .react-datepicker__day:hover {
      background-color: lighten($color-main, 40%);
    }

    .react-datepicker__day--selected {
      background-color: $color-main;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: white;
      color: black;
    }
  }

  .react-datepicker__triangle {
    stroke: #f0f0f0;
  }
}