@import "assets/css/constants.scss";

.switch-input {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;

  label {
    cursor: pointer;
  }

  input {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #D9DADC;
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 #fff;
    transform: scale(0.84);
    transition: all 0.15s ease-in;
    cursor: pointer;
  
    &:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      background: transparent;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
      transition: all 0.15s ease-in;
    }
  
    &:checked {
      box-shadow: inset 20px 0 0 0 $color-main;
      border-color: $color-main;
  
      &:after {
        left: 20px;
        box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
      }
    }
  }
}