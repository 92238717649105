.input-wrapper.slider {
  > div {
    display: flex;
    gap: 12px;

    input[type="text"] {
      width: 45px;
      pointer-events: none;
      user-select: none;
    }

    input[type="range"] {
      width: 100%;
      max-width: 220px;
      cursor: pointer;
    }
  }
}