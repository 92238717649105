@import 'assets/css/constants.scss'; 

.missions-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 18px 24px;
  background-color: $color-header-bar;
  border-top: 0.5px solid white;
  color: white;

  .actions {
    gap: 10px;
  
    .filter {
      color: #212731;
      display: flex;
      align-items: center;
      min-height: 35px;
      gap: 18px;
      padding: 0 12px;
      background-color: $color-white;
      border-radius: 5px;
      margin: 0 auto;
      cursor: pointer;
      transition: all 0.15s ease-in;

      span {
        display: flex;
        align-items: center;
        font-weight: 800;

        &:first-of-type {
          font-size: 18px;
        }

        &:last-of-type {
          font-size: 14px;
        }

        svg {
          margin: 0 8px;
        }
      }

      > svg {
        border: 1px solid black;
        height: 13px;
        width: 13px;
        padding: 2px;
        border-radius: 50%;
        flex-shrink: 0;
      }

      &:hover {
        background-color: #F2F2F2;
      }
    }

    button, .switch-input {
      width: 210px;
    }

    .switch-input label {
      margin-left: auto;
    }
  }

  .week-days {
    display: flex;
    gap: 16px;
    padding: 0 6px;

    .day {
      flex: 1;
      text-align: left;
      padding: 11px;
      background-color: #A3ADC2;
      font-size: 14px;
      font-weight: 800;
      color: #212731;
      border-radius: 6px;
    }
  }

  @media screen and (max-width: 1350px) {
    .week-days {
      padding: 0 4px;
      gap: 8px;
    }
  }
}